import React, { ReactNode } from 'react';
import { DropdownItem } from 'reactstrap';
import { Button, TextButton } from '@angellist/adapt';

import { ModalActions } from '../../types';
import useModal from '../hooks/useModal';

type Props = {
  buttonType?: 'link' | 'button' | 'dropdown-item';
  buttonProps?: any;
  label: string;
  modalActions?: ModalActions;
  modalComponent: any;
  modalProps?: any;
  buttonIcon?: ReactNode;
};

const AdaptModalButton = (props: Props) => {
  const {
    buttonType = 'button',
    modalActions,
    modalProps,
    label,
    modalComponent: ModalComponent,
    buttonProps = {},
    buttonIcon,
  } = props;
  const modalState = modalActions ?? useModal();
  const { modal, modalKey, openModal, closeModal } = modalState;

  const handlePress = () => openModal();

  return (
    <>
      {buttonType === 'link' && (
        <TextButton label={label} onPress={handlePress} {...buttonProps} />
      )}
      {buttonType === 'button' && (
        <Button label={label} onPress={handlePress} {...buttonProps} />
      )}
      {buttonType === 'dropdown-item' && (
        <DropdownItem tag="a" {...buttonProps} onClick={handlePress}>
          {buttonIcon}
          {label}
        </DropdownItem>
      )}
      {modal && (
        <ModalComponent
          key={modalKey}
          modal={modal}
          closeModal={closeModal}
          {...modalProps}
        />
      )}
    </>
  );
};

export default AdaptModalButton;
